@import "fonts-definitions.css";


:root {
    box-sizing: border-box;
    --side-margin: .6em;
    --side-padding: .6em;

    --objects-border-radius: 1em;
    --headings-fonts: "Borel", "cursive";
    --body-font: "Barlow", "sans-serif";
    --lighter-headings: 400;
    --bold-headings: 600;

    --container-max-width: 1200px;
    --box-shadow: 1px 4px 12px rgba(0, 0, 0, .3);

    --fs-normal: clamp(16px, 1vw + 0.5rem, 20px);
    --fs-h2: clamp(22px, 1vw + 1rem, 32px);

    --primary-color: #292874;
    --accent-color: #11526e;
    --page-bg-color: #f2f3fa;
    --surface-color: #F8F8F8;
    --body-font-color: #333;
    --services-bg-color: #f3fbfd;

    --heading-letter-spacing: 0.01em;
    --paragraph-letter-spacing: 0.02em;
    --paragraph-line-height: 1.7;
}

*, ::before, ::after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

body {
    background-color: var(--page-bg-color);
    font-family: var(--body-font);
}

.button {
    padding: .8em .8em;
    font-size: 1rem;
    cursor: pointer;
    background-color: var(--surface-color);
    color: var(--primary-color);
    border-radius: var(--objects-border-radius);
    border: 1px solid var(--primary-color);
}

.button:hover {
    background-color: var(--primary-color);
    color: var(--surface-color);
}

/****** MAIN HEADER STYLE *********************/
header.main-header {
    background-color: var(--surface-color);
}

.main-header .header-container {
    display: grid;
    padding-top: 1em;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
    margin: auto;
    max-width: var(--container-max-width);
}

@media screen and (min-width: 800px) {
    .main-header .header-container {
        grid-template-columns: 1fr 3fr;
        padding-top: 0;
    }

    .main-header {
        padding: 1em;
    }

    .main-header nav {
        justify-self: end;
    }
}

.main-header img {
    width: 200px;
    height: auto;
}

.main-header nav {
    margin: 1em 0;
}

.main-header nav ul {
    display: flex;
    list-style: none;
    gap: .5em;
}

.main-header nav li {
    list-style: none;
}

.main-header nav a {
    display: block;
    padding: 0.5em 0.5em;
    text-decoration: none;
    background: var(--surface-color);
    border-radius: var(--objects-border-radius);
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    letter-spacing: var(--paragraph-letter-spacing);
    text-transform: uppercase;
    font-size: .75rem;
    font-weight: bold;
}

@media screen and (min-width: 25em) {

    .main-header nav a {
        font-size: .9rem;
        padding: 0.5em 0.8em;
    }
}

@media screen and (min-width: 37.5em) {

    .main-header nav a {
        padding: 0.5em 1em;
    }
}


.main-header nav a:visited {
    color: var(--primary-color);
}

.main-header nav a:hover, .main-header nav a.active {
    color: var(--surface-color);
    background-color: var(--primary-color)
}

/*************** HERO STYLES *******************/
.hero-container {
    position: relative;
}

.hero-container img {
    max-width: 100%;
    display: block;
    height: 360px;
    object-fit: cover;
    object-position: top right;
}

@media (min-width: 50em) {
    .hero-container img {
        height: 460px;
        object-position: top right;
    }

}

.hero-container .hero-text {
    position: absolute;
    font-family: "HeroicCondensed-Medium", sans-serif;
    top: 30%;
    left: 1%;

    @media screen and (min-width: 400px) {
        left: 3%;
    }

    @media screen and (min-width: 1220px) {
        left: 10%;
    }
}

.hero-text h1 {
    font-size: clamp(50px, 5vw + 1rem, 100px);
    text-shadow: .1rem .1rem .5rem rgba(0, 0, 0, .4);
    text-align: left;
    text-transform: uppercase;
    color: white;
    word-spacing: 0.1em;
    letter-spacing: -0.05em;
    font-weight: 400;
    line-height: 1.1;
    font-style: italic;
}

.hero-text h1 span {
    display: block;
}

.hero-text h1 span:last-of-type {
    font-size: 2rem;
    font-weight: 300;
    text-align: right;
    letter-spacing: 0;
}

@media screen and (max-width: 390px) {
    .hero-text h1 span:last-of-type {
        text-align: left;
    }
}

.hero-text .hash-tag {
    color: white;
    font-size: 2rem;
    font-style: italic;
    line-height: 1.2;
    padding: 0 1em;
    width: min-content;
}


/******************  SEARCH BOOKING FORM *********************/
.search-booking-container {
    position: relative;
    top: -50px;
    max-width: var(--container-max-width);
    margin: 0 var(--side-margin);
    padding: 2em var(--side-padding);
    background: white;
    box-shadow: var(--box-shadow);
    border-radius: var(--objects-border-radius);
}

@media screen and (min-width: 1220px) {

    .search-booking-container {
        margin: auto;
    }
}

.src_flights_btn_container {
    display: flex;
    margin-top: .5em;
}

.src_flights_btn {
    padding: 1em 3.75em;
    font-size: 1rem;
    cursor: pointer;
    color: white;
    background: var(--primary-color);
    border-radius: 2em;
    border: 1px solid var(--primary-color);
    margin: auto;
}

@media screen and (min-width: 62.5em) {

    .src_flights_btn_container .src_flights_btn {
        margin: 0 0 0 auto;
    }
}


.search-booking-container form {
    font-size: 1rem;
}

.search-booking-container form input {
    padding: 0.75em;
    font-size: .8rem;
    width: 100%;
    line-height: 1.2;
    border: 1px solid rgba(0, 0, 0, .4);
    border-radius: .4em;
}

form div {
    margin-bottom: .5em;
}

form label {
    display: block;
    padding: .5em 0;
}

form .trip_type {
    display: flex;
    gap: .3em;
    align-items: center;
}

form .trip_type label {
    display: inline;
}

form .trip_type input[type="radio"] {
    height: 24px;
    width: 24px;
}


@media screen and (min-width: 37.5em) {
    .booking-form-fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: .5em;
    }
}

@media screen and (min-width: 62.5em) {
    .booking-form-fields {
        grid-template-columns: repeat(4, 1fr);
        column-gap: .2em;
    }

    form div {
        margin-bottom: 0;
    }

    .trip_dates, .passenger_class {
        display: flex;
    }

    .passenger_class #flight_class_input, .trip_dates #returning_date_input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
    }

    .passenger_class #passengers_input, .trip_dates #departure_date_input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .passenger_class .flight_class {
        flex: 1.3;
    }

    .passenger_class .passengers {
        flex: 2;
    }
}


/******************* ABOUT US SECTION STYLES  *****************/
.about-us {
    margin: 0 var(--side-margin);
    max-width: var(--container-max-width);
    background: white;
    padding-bottom: 2em;
    z-index: 1;
    color: var(--primary-color);
    position: relative;
    border-radius: var(--objects-border-radius);
    box-shadow: var(--box-shadow);
}

@media screen and (min-width: 1220px) {
    .about-us {
        margin: 0 auto;
    }
}

.about-us h2 {
    text-align: center;
    font-family: var(--headings-fonts);
    text-transform: capitalize;
    letter-spacing: var(--heading-letter-spacing);
    font-weight: 600;
    padding: .5em 0;
    font-size: var(--fs-h2);
    color: var(--primary-color);
}

.about-us .about-us-description {
    display: flex;
    flex-direction: column;
    padding: 1em;
    justify-content: flex-end;
}

.about-us button {
    background: white;
    font-size: 1em;
    text-transform: capitalize;
    position: relative;
    border: 2px solid var(--primary-color);
    border-radius: var(--objects-border-radius);
    margin: .5em 0 0 auto;
}

.about-us button a{
    text-decoration: none;
    display: block;
    cursor: pointer;
    padding: 1em 1.5em;
    width: 100%;
    height: 100%;
}

.about-us button a:visited,
.about-us button a:focus{
    color: var(--primary-color);
}

.about-us p {
    font-size: var(--fs-normal);
    line-height: var(--paragraph-line-height);
    letter-spacing: var(--paragraph-letter-spacing);
}

.about-us img {
    max-width: 100%;
    height: auto;
    min-height: 250px;
    object-fit: cover;
    object-position: center;
    display: block;
    border-radius: var(--objects-border-radius) var(--objects-border-radius) 0 0;
}


@media screen and (min-width: 600px) {
    .about-us {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 3em 0;
    }

    .about-us h2 {
        text-align: left;
    }

    .about-us img {
        max-width: 90%;
        margin: auto;
        border-radius: 0;
    }
}

/**************************************/


/************ OUR SERVICES STYLES *****************/
.our-services {
    margin: 0 auto;
    padding: 5em 0 1em;
    background-color: var(--services-bg-color);
    position: relative;
    top: -40px;
}

.our-services button {
    background: white;
    color: var(--primary-color);
    font-size: 1em;
    position: relative;
    text-transform: capitalize;
    border: 2px solid var(--primary-color);
    border-radius: var(--objects-border-radius);
    margin: .5em 0 0 auto;
    box-shadow: var(--box-shadow);
}


.our-services button a{
    text-decoration: none;
    display: block;
    cursor: pointer;
    padding: 1em 1.5em;
    width: 100%;
    height: 100%;
}

.our-services button a:visited,
.our-services button a:focus{
    color: var(--primary-color);
}

.our-services h2 {
    text-align: center;
    font-family: var(--headings-fonts);
    font-weight: var(--bold-headings);
    color: var(--primary-color);
    letter-spacing: var(--heading-letter-spacing);
    padding: 1.5em;
    font-size: var(--fs-h2);
    text-transform: capitalize;
}

.our-services .services-container {
    display: grid;
    grid-template-columns: 1fr;
    margin: auto;
    width: 90%;
    justify-self: center;
    gap: .8em;
    max-width: var(--container-max-width);
}

@media screen and (min-width: 600px) {
    .our-services .services-container {
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
        padding: 0 var(--side-margin);
        gap: .4em;
    }

    .our-services button {
        grid-column: 3;
    }
}

@media screen and (min-width: 1220px) {

    .our-services .services-container {
        margin: 0 auto;
        gap: .8em;
    }
}

.services-container .service {
    background: var(--primary-color);
    padding: 1em;
    color: var(--primary-color);
    text-align: center;
    border: 2px solid var(--primary-color);
    background: white;
    border-radius: var(--objects-border-radius);
    box-shadow: var(--box-shadow);
}

.services-container .service img {
    display: block;
    margin: .5em auto 0;
    width: 4em;
    height: 4em;
    padding: 0.5em;
    background-color: var(--page-bg-color);
    border-radius: 40px;
}

.services-container .service h3 {
    padding: 1em .2em .2em;
    font-family: var(--headings-fonts);
    text-align: center;
    letter-spacing: var(--heading-letter-spacing);
    margin-bottom: .4em;
    font-weight: 600;
    border-bottom: 2px dashed var(--primary-color);
    text-transform: capitalize;
}

.services-container .service p {
    line-height: var(--paragraph-line-height);
    letter-spacing: var(--paragraph-letter-spacing);
    font-size: var(--fs-normal);
}

/**************** OUR CLIENTS **********************/
.our-clients {
    margin: 0 auto;
    padding: 5em 0 8em;
    background-color: var(--services-bg-color);
    position: relative;
    top: -40px;
}

.our-clients button {
    background: white;
    padding: 1em 1.5em;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 1em;
    position: relative;
    text-transform: capitalize;
    border: 2px solid var(--primary-color);
    border-radius: var(--objects-border-radius);
    margin: .5em 0 0 auto;
    box-shadow: var(--box-shadow);
}

.our-clients h2 {
    text-align: center;
    font-family: var(--headings-fonts);
    font-weight: var(--bold-headings);
    color: var(--primary-color);
    padding: 1.5em;
    letter-spacing: var(--heading-letter-spacing);
    font-size: var(--fs-h2);
    text-transform: capitalize;
}

.our-clients .clients-container {
    display: grid;
    grid-template-columns: 1fr;
    margin: auto;
    width: 90%;
    justify-self: center;
    gap: .8em;
    max-width: var(--container-max-width);
}

@media screen and (min-width: 600px) {
    .our-clients .clients-container {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        padding: 0 var(--side-margin);
        gap: .4em;
    }
}

@media screen and (min-width: 800px) {

    .our-clients .clients-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: 1220px) {

    .our-clients .clients-container {
        margin: 0 auto;
        gap: .8em;
    }
}

.clients-container .client {
    background: var(--primary-color);
    padding: 1em;
    color: var(--primary-color);
    text-align: center;
    border: 2px solid var(--primary-color);
    background: white;
    border-radius: var(--objects-border-radius);
    box-shadow: var(--box-shadow);
}

.clients-container .client img {
    display: block;
    margin: .5em auto 0;
    width: 80%;
    height: 100px;
    padding: 1em .5em;
    object-fit: contain;
    background-color: var(--page-bg-color);
}

.clients-container .client h3 {
    padding: 1em .2em .2em;
    font-family: var(--headings-fonts);
    text-align: center;
    letter-spacing: var(--heading-letter-spacing);
    margin-bottom: .4em;
    font-weight: 600;
    border-bottom: 2px dashed var(--primary-color);
    text-transform: capitalize;
}

.clients-container .client p {
    line-height: var(--paragraph-line-height);
    letter-spacing: var(--paragraph-letter-spacing);
    font-size: var(--fs-normal);
}

/********** POPULAR DESTINATIONS STYLES **************/
.popular-destinations {
    background: white;
    position: relative;
    top: -90px;
    color: var(--primary-color);
    border-radius: var(--objects-border-radius);
    box-shadow: var(--box-shadow);
    padding: var(--side-padding);
    margin: 0 auto;
    z-index: 5;
    max-width: var(--container-max-width);
}

@media screen and (max-width: 1184px) {
    .popular-destinations {
        margin: 0 var(--side-margin) 2em;
    }
}

.popular-destinations h2 {
    text-align: center;
    padding: .8em;
    letter-spacing: var(--heading-letter-spacing);
    font-family: var(--headings-fonts);
    text-transform: capitalize;
    color: var(--primary-color);
    font-size: var(--fs-h2);
}

.destination button {
    background: white;
    padding: 1em 1.5em;
    color: var(--primary-color);
    cursor: pointer;
    font-size: 1rem;
    position: relative;
    border: 2px solid var(--primary-color);
    border-radius: var(--objects-border-radius);
    margin-top: 1em;
}

.destination p {
    font-size: var(--fs-normal);
    line-height: var(--paragraph-line-height);
    letter-spacing: var(--paragraph-letter-spacing);
}

.destination h3 {
    color: var(--primary-color);
    font-family: var(--headings-fonts);
    letter-spacing: var(--heading-letter-spacing);
    font-size: clamp(18px, 1vw + 0.5rem, 22px);
    padding: .5em 0;
}

.destination button:hover {
    color: var(--surface-color);
    background-color: var(--primary-color);
}

.destination {
    display: grid;
    grid-template-columns: 1fr;
    border-bottom: 2px dashed rgb(41, 40, 116, .7);
    padding: .4em .4em .8em .4em;
    align-items: center;
    margin-bottom: 2em;
}

.destination:last-of-type {
    border-bottom: none;
}

.destination img {
    width: 100%;
    aspect-ratio: 16/9;
    min-height: 200px;
    max-height: 300px;
    height: auto;
    display: block;
    object-fit: cover;
}

.destination .destination_description button {
    max-width: 180px;
}

@media screen and (min-width: 600px) {
    .destination {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 3em;
        border-bottom: none;
    }

    .destination img {
        border-radius: var(--objects-border-radius);
        aspect-ratio: 1/1;
    }

    .destination .destination_description {
        padding: 0 .5em;
    }
}

@media screen and (min-width: 700px) {
    .destination {
        display: grid;
    }


    .destination:nth-child(odd) img {
        grid-column: 2;
        grid-row: 1
    }

    .destination:nth-child(odd) .destination_description {
        grid-column: 1;
        grid-row: 1;
    }

    .destination .destination_description button {

    }

}

@media screen and (min-width: 850px) {

    .destination .destination_description button {

    }

    .destination .image_container {
        padding: 1em;
    }
}

/*********** FOOTER STYLES **********************/
.main_footer {
    background: var(--primary-color);
    position: relative;
}

.main_footer .wrapper_container {
    background: var(--services-bg-color);
    position: absolute;
    top: -150px;
    padding: 80px 0 20px;
    left: 0;
    right: 0;
}

.footer_nav_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    border-top: 2px dashed var(--primary-color);
    padding: 1em;
    margin-bottom: 1em;
}

.footer_nav_container img {
    display: block;
    max-width: 100%;
    height: auto;
}

.footer_nav_container ul {
    list-style: none;
}

.footer_nav_container nav a {
    padding: .8em;
    text-decoration: none;
    color: var(--accent-color);
    font-weight: bold;
    font-size: clamp(1.125rem, .5rem + 1vw, 1.25rem);
    line-height: var(--paragraph-line-height);
}

.footer_nav_container nav a:visited {
    color: var(--accent-color);
}

@media screen and (pointer: fine){
    .footer_nav_container nav a:hover {
        color: #21a4dd;
        text-decoration: underline;
    }
}

.footer_nav_container .social-links {
    display: flex;
    column-gap: .2em;
    margin-top: 1em;
    font-size: 2rem;
}

.social-links a {
    cursor: pointer;
}

.social-links .fa {
    color: var(--accent-color);
}

.footer_inner {
    max-width: var(--container-max-width);
    margin: 1em auto 0;
    color: var(--primary-color)
}

.footer_inner address {
    font-style: normal;
    margin-bottom: 1em;
    border-bottom: 2px dashed var(--primary-color);
    padding: 1em;
}

.footer_inner p a {
    color: var(--accent-color);
    font-weight: bold;
}

.footer_inner p a:visited {
    color: var(--accent-color);
}

.footer_inner address p {
    font-size: 1rem;
    word-break: break-all;
    line-height: 1.4;
    padding:.5em 0;
    letter-spacing: var(--paragraph-letter-spacing);
    color: var(--accent-color);
}

.footer_inner address:last-of-type {
    border-bottom: none;
}

.footer_inner address h4 {
    padding: .3em 0;
    letter-spacing: var(--heading-letter-spacing);
    font-size: clamp(1rem, 1vw + .5rem, 1.25rem);
}

.footer_inner address h5 {
    font-size: clamp(15px, 1vw + .5rem, 18px);
    margin: 1em 0 .5em;
    letter-spacing: var(--heading-letter-spacing);
}

@media screen and (min-width: 550px) {

    .footer_inner {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: .5em;
    }

    .footer_inner address:last-of-type {
        grid-column: span 3;
        justify-self: center;
    }

    .footer_nav_container {
        grid-column: span 3;
        margin-bottom: 0;
        grid-template-columns: 2fr 1fr;
    }

    .footer_nav_container nav ul {
        display: flex;
    }

    .footer_inner address {
        padding: .5em;
        border-bottom: none;
    }

}

@media screen and (min-width: 800px) {

    .main_footer .wrapper_container {
        padding-top: 100px;
    }

    .footer_inner {
        grid-template-columns: repeat(4, 1fr);
    }

    .footer_inner address {
        margin-bottom: 0;
    }

    .footer_inner address:last-of-type {
        grid-column: 4;
        justify-self: start;
    }

    .footer_nav_container {
        grid-column: 1/span 4;
    }

    .footer_nav_container img {
        height: auto;
        width: 80%;
        object-fit: cover;
    }
}

@media screen and (max-width: 1184px) {

    .footer_inner {
        margin: 0 var(--side-margin);
    }
}

/******** NOT FOUND STYLES *************/
.page-container {
    min-height: 100vh;
    margin-bottom: 200px;
}

.not-found-text {
    text-align: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background: url("../public/images/not-found-hero-image.jpg") no-repeat 50%;
    color: white;
    text-shadow: 1px 1px 1px rgba(0,0,0, .4);
}

.page-hero-container {
    position: relative;
}

/********* STYLES FOR PAGE HERO IMAGES********************/
.page-hero-container img {
    aspect-ratio: 4/1;
    height: auto;
    max-height: 420px;
    min-height: 320px;
    width: 100%;
    object-fit: cover;
}

.page-hero-container .page-hero-text {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: var(--container-max-width);
    margin: 0 auto;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 30px;
    font-family: var(--headings-fonts);
    font-style: italic;
    font-size: clamp(18px, 1vw + 1rem, 32px);;
    text-shadow: .1rem .5rem .2rem rgba(0, 0, 0, .4);
    color: white;
    line-height: 1.6;
}

/*************** STYLES FOR PAGE CONTENT FOR TEXT BASED CONTAINER ***********/
.page-text-container {
    max-width: 1024px;
    margin: 0 auto;
    padding: 1em;
}

.page-text-container p {
    font-size: clamp(16px, 1vw + 0.5rem, 20px);
    line-height: var(--paragraph-line-height);
    letter-spacing: var(--paragraph-letter-spacing);
    padding-top: 2em;
}

.page-text-container h2 {
    padding: 2em 0 1em;
    text-align: center;
    letter-spacing: var(--heading-letter-spacing);
    font-size: var(--fs-h2);
    color: var(--primary-color);
    font-family: var(--headings-fonts);
}

.page-text-container .text-image {
    display: block;
    margin: 0 auto;
    max-width: 350px;
    height: auto;
    width: 100%;
    max-height: 350px;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50%;
}

@media screen and (min-width: 800px) {

    .page-text-container .text-image {
        max-height: 280px;
        max-width: 280px;
    }
}

.values-container {
    display: grid;
    grid-template-columns: 1fr;
    background-image: url("../public/images/core-values-background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: white;
    position: relative;
}

.values-container::before {
    content: " ";
    display: block;
    background: #333333aa;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.values-container .value {
    z-index: 2;
}

@media screen and (min-width: 560px) {

    .values-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (min-width: 960px) {

    .values-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

.values-container .value {
    padding: 1em;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
    text-align: center;
}

.values-container .value h3 {
    border-bottom: 2px dashed white;
    padding-bottom: .5em;
}

.values-container .value:nth-child(even) {
    background: rgba(0, 0, 0, .4);
}

.values-container .value:nth-child(odd) {
    background: rgba(0, 0, 0, .2);
}

/*********** Styles for individual service section in our services page *******************/

.service-container {
    clear: both;
}

.service-container p {
    padding-top: .5em;
}

.service-container img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: auto;
}

@media screen and (min-width: 700px) {

    .service-container:nth-child(odd) img {
        margin-left: 1em;
    }

    .service-container:nth-child(even) img {
        margin-right: 1em;
    }

    .service-container .corporate-svc-image,
    .service-container .ticketing-svc-image {
        max-width: 60%;
    }

    .service-container .corporate-svc-image {
        float: right;
    }

    .service-container .ticketing-svc-image {
        float: left;
    }
}

@media screen and (min-width: 800px) {

    .service-container .corporate-svc-image {
        max-width: 100%;
    }
}

/*************** CONTACT US PAGE ******************/
.locations-container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1em;
    padding: .5em;
}

@media screen and (min-width: 600px) {

    .locations-container {
        grid-template-columns: 1fr 1fr;
        gap: 1em;
    }
}

.office-location {
    background: white;
    padding: 1em;
    color: var(--primary-color);
    box-shadow: var(--box-shadow);
    border-radius: var(--objects-border-radius);
}

.office-location h3 {
    text-align: center;
    font-family: var(--headings-fonts);
    letter-spacing: var(--heading-letter-spacing);
    padding-top: .5em;
    font-size: clamp(1.125rem, 1vw + 0.5rem, 1.375rem);
}

.office-location p {
    letter-spacing: var(--paragraph-letter-spacing);
    line-height: var(--paragraph-line-height);
    padding-top: 0;
    padding-left: 10px;
}

.office-location a,
.office-location a:visited,
.office-location a:focus {
    color: var(--primary-color);
}

.office-location a {
    text-decoration: none;
    display: block;
}


@container (min-width: 400px) {

    .office-location a {
        display: inline;
    }

    .office-location a + a {
        margin-left: 0.625em;
        padding-left: 0.625em;
        border-left: 2px solid var(--primary-color);
    }

    .office-location .buttons a {
        font-size: .8rem;
    }
}


.office-location > div {
    display: flex;
    align-items: center;
    padding: .5em 0;
}

.office-location > div img {
    height: 32px;
    width: 32px;
}

.office-location .buttons {
    display: flex;
    gap: 1em;
}

.office-location button {
    border: none;
    background: none;
}

.office-location .buttons a {
    display: block;
    background: white;
    font-size: clamp(0.8125rem, 1vw + .5rem, 1rem);
    padding: 0.625em 1.5em;
    border: 2px solid var(--primary-color);
    border-radius: 2em;
    cursor: pointer;
}

/********* dialog styles *************/
#alert-dialog {
    max-width: 90%;
    padding: .8em 1em;
    border: none;
    margin: auto;
    border-radius: var(--objects-border-radius);
    background: var(--page-bg-color);
    box-shadow: 5px 5px 5px rgba(0,0,0,.4);
}

#alert-dialog div{
    display: flex;
    flex-direction: column;

}
#alert-dialog h4 {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: .5em;
}

#alert-dialog p {
    line-height: 2;
    font-size: 1.1rem;
}

#alert-dialog .close-button {
    margin-left: auto;
    display: block;
    border: none;
    border-radius: var(--objects-border-radius);
    background: var(--primary-color);
    color: white;
    padding: .8em 1em;
    cursor: pointer;
    margin-bottom: .5em;
}

/************ LOADING PAGE ******************/
.loading-container {
    display: flex;
    height: 100vh;
}

.loading-container img {
    width: 250px;
    height: auto;
    margin: auto;
}